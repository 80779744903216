<template>
  <!-- begin::Card -->
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Reportes de proceso de matrícula
          <span class="d-block text-muted pt-2 font-size-sm"
          >Aqui se podra visualizar y descargar los reportes referentes a los procesos de matrícula.</span
          >
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <v-card
          class="pa-5 pr-8 rounded-lg elevation-0 mb-5"
          outlined
      >
        <IconCardWithActionButton
            :loading="false"
            buttonLegend="Descargar reporte"
            colorTheme="green"
            icon="file-excel"
            subTitle="Registro de cuantos estudiantes de antiguo ingreso han cancelado y se han matrículado."
            title="Progreso de matrícula de estudiantes de antíguo ingreso"
            @action="openSummaryOfEnrolledStudentReport()"
        ></IconCardWithActionButton>
      </v-card>
    </div>
  </div>
  <!-- end::Card -->
</template>


<script>
import IconCardWithActionButton from "@/components/elements/dashboard/IconCardWithAction.vue";

export default {
  name: "EnrollmentReports",
  components: {IconCardWithActionButton},
  title: "Reportes de proceso de matrícula",

  data() {
    return {}
  },

  methods: {
    openSummaryOfEnrolledStudentReport() {
      window.open("https://reportsge.ricaldone.edu.sv/public/api/enrollment-process/payments/excel/2024")
    }
  }
}
</script>


<style scoped>

</style>
